import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)
// import request from '@/utils/requset'
import { get, post, del } from '@/utils/requset'
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$del = del
// Vue.prototype.$http = request
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

const state = {
    name: ""
};
const mutations = {
    getName(state, name) {
        // 变更状态
        state.name = name
    }
};
const actions = {
};
const getters = {
};

// 对外暴露
export default {
    name: 'nav',
    state,
    mutations,
    actions,
    getters
}
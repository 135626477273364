<!--
 * @Descripttion: 
 * @version: 
 * @Author: king
 * @Date: 2023-05-13 09:15:39
 * @LastEditors: wei
 * @LastEditTime: 2023-05-13 09:32:46
-->
<template>
  <div>
    <navbar></navbar>
    <router-view></router-view>
    <bottomBar></bottomBar>
  </div>
</template>

<script>
import navbar from '@/components/navbar';
import bottomBar from '@/components/bottom-bar';
export default {
  components: { navbar, bottomBar },
};
</script>

<style></style>

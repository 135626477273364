/*
 * @Descripttion:
 * @version:
 * @Author: king
 * @Date: 2023-05-13 09:15:39
 * @LastEditors: wei
 * @LastEditTime: 2023-05-13 09:52:45
 */
import layout from '@/layout';
export default [
  {
    name: 'home',
    path: '/',
    component: layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/pages/home'),
      },
      {
        path: '/annoncement',
        name: 'annoncement',
        component: () => import('@/pages/annoncement'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/register'),
      },
      {
        path: '/details',
        name: 'details',
        component: () => import('@/pages/details'),
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('@/pages/search'),
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('@/pages/aboutus'),
      },
      {
        path: '/new',
        name: 'new',
        component: () => import('@/pages/new'),
      },
    ],
  },
];

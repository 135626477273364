<!--
 * @Descripttion: 
 * @version: 
 * @Author: king
 * @Date: 2023-05-13 09:31:48
 * @LastEditors: wei
 * @LastEditTime: 2023-05-13 09:49:19
-->
<template>
    <footer class="page-footer">
        <div class="content">
            <!-- <div v-for="(item, index) in list" :key="`bottom-bar-${index}`">
        <h2>{{ item.title }}</h2>
        <div class="flex">
          <a style="display: block" v-for="(ite, inde) in item.children" :key="`bottom-bar-${index}-${inde}`">{{ ite.title
          }}</a>
        </div>
      </div> -->
            <span>地址：江西省南昌市青山湖区丹霞路333号 邮箱：ruheng0604@163.com</span>
			<span style="margin-top: 20px;">联系人：魏薇  电话：13803545727</span>
        </div>
        <div style="
        text-align: center;
        padding: 20px;
        border-top: 1px solid #34445c;
        font-size: 16px;
      ">
            赣ICP备2024030584号-1
        </div>
        <div class="imageback"></div>

    </footer>
</template>
<script>
export default {
    data() {
        return {
            list: [
                {
                    title: '关于我们',
                    children: [
                        { title: '常见问题' },
                        { title: '用户协议' },
                        { title: '隐私协议' },
                    ],
                },
                {
                    title: '关于我们',
                    children: [
                        { title: '常见问题' },
                        { title: '用户协议' },
                        { title: '隐私协议' },
                    ],
                },
                {
                    title: '关于我们',
                    children: [
                        { title: '常见问题' },
                        { title: '用户协议' },
                        { title: '隐私协议' },
                    ],
                },
            ],
        };
    },
};
</script>
<style lang="scss" scoped>
.page-footer {
    background-color: #2a303c;
    color: white;
    position: relative;

    .content {
        width: 1240px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 40px 0 20px 0;
        display: flex;
        flex-direction: column;
        h2 {
            font-size: 18px;
            margin-top: 20px;
        }

        a {
            font-size: 14px;
            margin-top: 20px;
            cursor: pointer;
        }
    }
}
.imageback {
    // position: absolute;
    // bottom: 45%;
    // transform: translateY(50%);
    // left: 5%;
    z-index: 99;
    width: 500px;
    height: 70px;
    background: url("@/assets/copyright_white.png");
    background-size: cover;
    margin: 0 auto;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)
import createPersistedState from "vuex-persistedstate";
// 引入组件化小仓库
import nav from '@/store/nav'

//创建VueX对象
const store = new Vuex.Store({
    // 实现vuex仓库模块式开发存储数据
    modules: {
        nav
    },
    plugins: [createPersistedState(
        { storage: window.localStorage }
    )],
})

export default store